<template>
  <div v-if="!itemAsIFrame" class="global-footer">
    <div class="container global-footer__wrapper">
      <!-- Top Row -->
      <div class="row top-row">
        <div class="col-12">
          <div class="border-black"></div>
        </div>
        <div class="col-12 col-md2-4 mb-5 mb-md2-0 wrapper-top">
          <div class="logo">
            <NuxtImg :src="logoUrl" class="img-fluid" height="25" width="97" />
          </div>
          <div class="description">
            <p v-if="footer?.elements?.newsletterText?.value">
              {{ footer?.elements?.newsletterText?.value }}</p
            >
          </div>
          <div class="subscribe">
            <div class="input-group">
              <input
                ref="emailInputRef"
                aria-label="Enter your email"
                class="form-control"
                placeholder="Enter your email"
                type="text"
              />
              <button class="btn btn-primary" type="button" @click="toggleNewsletter()">
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 offset-md2-1 col-md2-4 my-3 wrapper-bottom">
          <div v-if="subNavigationItems" class="row">
            <template v-for="parent in subNavigationItems" :key="parent.itemId">
              <ul class="nav-links col-6 pl-md2-2">
                <li v-for="(item, index) in parent.subItems" :key="index">
                  <NuxtLink :to="item.url">{{ item.title }}</NuxtLink>
                </li>
              </ul>
            </template>
          </div>
        </div>
        <div class="col-12 offset-md2-1 col-md2-2">
          <ul class="social-links">
            <li>
              <a :href="footer.elements.facebookLink.value">
                <IconFacebook />
                Facebook
              </a>
            </li>
            <li>
              <a :href="footer.elements.linkedinLink.value">
                <IconLinkedIn />
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Bottom Row -->
      <div class="row bottom-row">
        <div class="col-12">
          <div class="border-gold"></div>
        </div>
        <div class="bottom-row-wrapper">
          <div class="attorney-text">
            <p v-if="footer?.elements?.legalText?.value">
              {{ footer?.elements?.legalText?.value }}</p
            >
          </div>
          <div class="copyright-text">
            <p v-if="footer?.elements?.companyName?.value"
              >© {{ currentYear }} {{ footer?.elements?.companyName?.value }}</p
            >
          </div>
          <div class="legal-links-wrapper">
            <ul class="legal-links">
              <li v-for="legalPage in legalPages" :key="legalPage?.itemId">
                <NuxtLink :to="legalPage?.url">{{ legalPage?.title }}</NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <PardotSubscriptionsModal :preselected-items="newsLetterItems" />
  </div>
</template>
<script lang="ts" setup>
import type { ApiResponse } from '~/types/ApiResponse'
import type { NavigationItem } from '~/types/NavigationItem'
import type { GlobalFooterModel } from '~/models'
import { usePardotModalStore } from '~/stores/modals/pardot-modal'

const itemAsIFrame = useState<boolean>('itemAsIframe')

const currentYear = new Date().getFullYear()
const siteStore = useSiteStore()
const subscriptionModal = usePardotModalStore()
const { items: newsLetterItems } = storeToRefs(subscriptionModal)

const emailInputRef = ref<HTMLInputElement | null>(null)

const toggleNewsletter = () => {
  subscriptionModal.setEmail((emailInputRef?.value as HTMLInputElement).value ?? '')
  subscriptionModal.toggleModal()
}

const { $footer } = useNuxtApp()
const footerResponse = $footer as ApiResponse<NavigationItem[]>

const subNavigationItems = footerResponse.data
  ?.filter((item: NavigationItem) => item.subItems.length > 0)
  .map((item: NavigationItem) => {
    return item
  })

const footer = siteStore.getLinkedItem<GlobalFooterModel>('global_footer')
const logoUrl = ref('')
if (footer?.elements?.logo?.value.length > 0) logoUrl.value = footer.elements.logo.value[0].url

const legalPages = footer.elements.legalPages.value.map((codename) => {
  return footerResponse.data?.find((item: NavigationItem) => item.codename == codename)
})
</script>
