export const countryList = [
    {
        "value": "53824",
        "text": "United States"
    },
    {
        "value": "53826",
        "text": "Canada"
    },
    {
        "value": "53828",
        "text": "Afghanistan"
    },
    {
        "value": "53830",
        "text": "Albania"
    },
    {
        "value": "53832",
        "text": "Algeria"
    },
    {
        "value": "53834",
        "text": "American Samoa"
    },
    {
        "value": "53836",
        "text": "Andorra"
    },
    {
        "value": "53838",
        "text": "Angola"
    },
    {
        "value": "53840",
        "text": "Anguilla"
    },
    {
        "value": "53842",
        "text": "Antarctica"
    },
    {
        "value": "53844",
        "text": "Antigua and Barbuda"
    },
    {
        "value": "53846",
        "text": "Argentina"
    },
    {
        "value": "53848",
        "text": "Armenia"
    },
    {
        "value": "53850",
        "text": "Aruba"
    },
    {
        "value": "53852",
        "text": "Australia"
    },
    {
        "value": "53854",
        "text": "Austria"
    },
    {
        "value": "53856",
        "text": "Azerbaijan"
    },
    {
        "value": "53858",
        "text": "Bahamas"
    },
    {
        "value": "53860",
        "text": "Bahrain"
    },
    {
        "value": "53862",
        "text": "Bangladesh"
    },
    {
        "value": "53864",
        "text": "Barbados"
    },
    {
        "value": "53866",
        "text": "Belarus"
    },
    {
        "value": "53868",
        "text": "Belgium"
    },
    {
        "value": "53870",
        "text": "Belize"
    },
    {
        "value": "53872",
        "text": "Benin"
    },
    {
        "value": "53874",
        "text": "Bermuda"
    },
    {
        "value": "53876",
        "text": "Bhutan"
    },
    {
        "value": "53878",
        "text": "Bolivia"
    },
    {
        "value": "53880",
        "text": "Bosnia and Herzegovina"
    },
    {
        "value": "53882",
        "text": "Botswana"
    },
    {
        "value": "53884",
        "text": "Brazil"
    },
    {
        "value": "53886",
        "text": "British Indian Ocean Territory"
    },
    {
        "value": "53888",
        "text": "British Virgin Islands"
    },
    {
        "value": "53890",
        "text": "Brunei"
    },
    {
        "value": "53892",
        "text": "Bulgaria"
    },
    {
        "value": "53894",
        "text": "Burkina Faso"
    },
    {
        "value": "53896",
        "text": "Burundi"
    },
    {
        "value": "53898",
        "text": "Cambodia"
    },
    {
        "value": "53900",
        "text": "Cameroon"
    },
    {
        "value": "53902",
        "text": "Cape Verde"
    },
    {
        "value": "53904",
        "text": "Cayman Islands"
    },
    {
        "value": "53906",
        "text": "Central African Republic"
    },
    {
        "value": "53908",
        "text": "Chad"
    },
    {
        "value": "53910",
        "text": "Chile"
    },
    {
        "value": "53912",
        "text": "China"
    },
    {
        "value": "53914",
        "text": "Christmas Island"
    },
    {
        "value": "53916",
        "text": "Cocos (Keeling) Islands"
    },
    {
        "value": "53918",
        "text": "Colombia"
    },
    {
        "value": "53920",
        "text": "Comoros"
    },
    {
        "value": "53922",
        "text": "Congo"
    },
    {
        "value": "53924",
        "text": "Cook Islands"
    },
    {
        "value": "53926",
        "text": "Costa Rica"
    },
    {
        "value": "53928",
        "text": "Croatia"
    },
    {
        "value": "53930",
        "text": "Cuba"
    },
    {
        "value": "53932",
        "text": "Curaçao"
    },
    {
        "value": "53934",
        "text": "Cyprus"
    },
    {
        "value": "53936",
        "text": "Czech Republic"
    },
    {
        "value": "53938",
        "text": "Côte d’Ivoire"
    },
    {
        "value": "53940",
        "text": "Democratic Republic of the Congo"
    },
    {
        "value": "53942",
        "text": "Denmark"
    },
    {
        "value": "53944",
        "text": "Djibouti"
    },
    {
        "value": "53946",
        "text": "Dominica"
    },
    {
        "value": "53948",
        "text": "Dominican Republic"
    },
    {
        "value": "53950",
        "text": "Ecuador"
    },
    {
        "value": "53952",
        "text": "Egypt"
    },
    {
        "value": "53954",
        "text": "El Salvador"
    },
    {
        "value": "53956",
        "text": "Equatorial Guinea"
    },
    {
        "value": "53958",
        "text": "Eritrea"
    },
    {
        "value": "53960",
        "text": "Estonia"
    },
    {
        "value": "53962",
        "text": "Ethiopia"
    },
    {
        "value": "53964",
        "text": "Falkland Islands"
    },
    {
        "value": "53966",
        "text": "Faroe Islands"
    },
    {
        "value": "53968",
        "text": "Fiji"
    },
    {
        "value": "53970",
        "text": "Finland"
    },
    {
        "value": "53972",
        "text": "France"
    },
    {
        "value": "53974",
        "text": "French Guiana"
    },
    {
        "value": "53976",
        "text": "French Polynesia"
    },
    {
        "value": "53978",
        "text": "French Southern Territories"
    },
    {
        "value": "53980",
        "text": "Gabon"
    },
    {
        "value": "53982",
        "text": "Gambia"
    },
    {
        "value": "53984",
        "text": "Georgia"
    },
    {
        "value": "53986",
        "text": "Germany"
    },
    {
        "value": "53988",
        "text": "Ghana"
    },
    {
        "value": "53990",
        "text": "Gibraltar"
    },
    {
        "value": "53992",
        "text": "Greece"
    },
    {
        "value": "53994",
        "text": "Greenland"
    },
    {
        "value": "53996",
        "text": "Grenada"
    },
    {
        "value": "53998",
        "text": "Guadeloupe"
    },
    {
        "value": "54000",
        "text": "Guam"
    },
    {
        "value": "54002",
        "text": "Guatemala"
    },
    {
        "value": "54004",
        "text": "Guernsey"
    },
    {
        "value": "54006",
        "text": "Guinea"
    },
    {
        "value": "54008",
        "text": "Guinea-Bissau"
    },
    {
        "value": "54010",
        "text": "Guyana"
    },
    {
        "value": "54012",
        "text": "Haiti"
    },
    {
        "value": "54014",
        "text": "Honduras"
    },
    {
        "value": "54016",
        "text": "Hong Kong S.A.R., China"
    },
    {
        "value": "54018",
        "text": "Hungary"
    },
    {
        "value": "54020",
        "text": "Iceland"
    },
    {
        "value": "54022",
        "text": "India"
    },
    {
        "value": "54024",
        "text": "Indonesia"
    },
    {
        "value": "54026",
        "text": "Iran"
    },
    {
        "value": "54028",
        "text": "Iraq"
    },
    {
        "value": "54030",
        "text": "Ireland"
    },
    {
        "value": "54032",
        "text": "Isle of Man"
    },
    {
        "value": "54034",
        "text": "Israel"
    },
    {
        "value": "54036",
        "text": "Italy"
    },
    {
        "value": "54038",
        "text": "Jamaica"
    },
    {
        "value": "54040",
        "text": "Japan"
    },
    {
        "value": "54042",
        "text": "Jersey"
    },
    {
        "value": "54044",
        "text": "Jordan"
    },
    {
        "value": "54046",
        "text": "Kazakhstan"
    },
    {
        "value": "54048",
        "text": "Kenya"
    },
    {
        "value": "54050",
        "text": "Kiribati"
    },
    {
        "value": "54052",
        "text": "Kuwait"
    },
    {
        "value": "54054",
        "text": "Kyrgyzstan"
    },
    {
        "value": "54056",
        "text": "Laos"
    },
    {
        "value": "54058",
        "text": "Latvia"
    },
    {
        "value": "54060",
        "text": "Lebanon"
    },
    {
        "value": "54062",
        "text": "Lesotho"
    },
    {
        "value": "54064",
        "text": "Liberia"
    },
    {
        "value": "54066",
        "text": "Libya"
    },
    {
        "value": "54068",
        "text": "Liechtenstein"
    },
    {
        "value": "54070",
        "text": "Lithuania"
    },
    {
        "value": "54072",
        "text": "Luxembourg"
    },
    {
        "value": "54074",
        "text": "Macao S.A.R., China"
    },
    {
        "value": "54076",
        "text": "Macedonia"
    },
    {
        "value": "54078",
        "text": "Madagascar"
    },
    {
        "value": "54080",
        "text": "Malawi"
    },
    {
        "value": "54082",
        "text": "Malaysia"
    },
    {
        "value": "54084",
        "text": "Maldives"
    },
    {
        "value": "54086",
        "text": "Mali"
    },
    {
        "value": "54088",
        "text": "Malta"
    },
    {
        "value": "54090",
        "text": "Marshall Islands"
    },
    {
        "value": "54092",
        "text": "Martinique"
    },
    {
        "value": "54094",
        "text": "Mauritania"
    },
    {
        "value": "54096",
        "text": "Mauritius"
    },
    {
        "value": "54098",
        "text": "Mayotte"
    },
    {
        "value": "54100",
        "text": "Mexico"
    },
    {
        "value": "54102",
        "text": "Micronesia"
    },
    {
        "value": "54104",
        "text": "Moldova"
    },
    {
        "value": "54106",
        "text": "Monaco"
    },
    {
        "value": "54108",
        "text": "Mongolia"
    },
    {
        "value": "54110",
        "text": "Montenegro"
    },
    {
        "value": "54112",
        "text": "Montserrat"
    },
    {
        "value": "54114",
        "text": "Morocco"
    },
    {
        "value": "54116",
        "text": "Mozambique"
    },
    {
        "value": "54118",
        "text": "Myanmar"
    },
    {
        "value": "54120",
        "text": "Namibia"
    },
    {
        "value": "54122",
        "text": "Nauru"
    },
    {
        "value": "54124",
        "text": "Nepal"
    },
    {
        "value": "54126",
        "text": "Netherlands"
    },
    {
        "value": "54128",
        "text": "New Caledonia"
    },
    {
        "value": "54130",
        "text": "New Zealand"
    },
    {
        "value": "54132",
        "text": "Nicaragua"
    },
    {
        "value": "54134",
        "text": "Niger"
    },
    {
        "value": "54136",
        "text": "Nigeria"
    },
    {
        "value": "54138",
        "text": "Niue"
    },
    {
        "value": "54140",
        "text": "Norfolk Island"
    },
    {
        "value": "54142",
        "text": "North Korea"
    },
    {
        "value": "54144",
        "text": "Northern Mariana Islands"
    },
    {
        "value": "54146",
        "text": "Norway"
    },
    {
        "value": "54148",
        "text": "Oman"
    },
    {
        "value": "54150",
        "text": "Pakistan"
    },
    {
        "value": "54152",
        "text": "Palau"
    },
    {
        "value": "54154",
        "text": "Palestinian Territory"
    },
    {
        "value": "54156",
        "text": "Panama"
    },
    {
        "value": "54158",
        "text": "Papua New Guinea"
    },
    {
        "value": "54160",
        "text": "Paraguay"
    },
    {
        "value": "54162",
        "text": "Peru"
    },
    {
        "value": "54164",
        "text": "Philippines"
    },
    {
        "value": "54166",
        "text": "Pitcairn"
    },
    {
        "value": "54168",
        "text": "Poland"
    },
    {
        "value": "54170",
        "text": "Portugal"
    },
    {
        "value": "54172",
        "text": "Puerto Rico"
    },
    {
        "value": "54174",
        "text": "Qatar"
    },
    {
        "value": "54176",
        "text": "Romania"
    },
    {
        "value": "54178",
        "text": "Russia"
    },
    {
        "value": "54180",
        "text": "Rwanda"
    },
    {
        "value": "54182",
        "text": "Réunion"
    },
    {
        "value": "54184",
        "text": "Saint Barthélemy"
    },
    {
        "value": "54186",
        "text": "Saint Helena"
    },
    {
        "value": "54188",
        "text": "Saint Kitts and Nevis"
    },
    {
        "value": "54190",
        "text": "Saint Lucia"
    },
    {
        "value": "54192",
        "text": "Saint Pierre and Miquelon"
    },
    {
        "value": "54194",
        "text": "Saint Vincent and the Grenadines"
    },
    {
        "value": "54196",
        "text": "Samoa"
    },
    {
        "value": "54198",
        "text": "San Marino"
    },
    {
        "value": "54200",
        "text": "Sao Tome and Principe"
    },
    {
        "value": "54202",
        "text": "Saudi Arabia"
    },
    {
        "value": "54204",
        "text": "Senegal"
    },
    {
        "value": "54206",
        "text": "Serbia"
    },
    {
        "value": "54208",
        "text": "Seychelles"
    },
    {
        "value": "54210",
        "text": "Sierra Leone"
    },
    {
        "value": "54212",
        "text": "Singapore"
    },
    {
        "value": "54214",
        "text": "Slovakia"
    },
    {
        "value": "54216",
        "text": "Slovenia"
    },
    {
        "value": "54218",
        "text": "Solomon Islands"
    },
    {
        "value": "54220",
        "text": "Somalia"
    },
    {
        "value": "54222",
        "text": "South Africa"
    },
    {
        "value": "54224",
        "text": "South Korea"
    },
    {
        "value": "54226",
        "text": "South Sudan"
    },
    {
        "value": "54228",
        "text": "Spain"
    },
    {
        "value": "54230",
        "text": "Sri Lanka"
    },
    {
        "value": "54232",
        "text": "Sudan"
    },
    {
        "value": "54234",
        "text": "Suriname"
    },
    {
        "value": "54236",
        "text": "Svalbard and Jan Mayen"
    },
    {
        "value": "54238",
        "text": "Swaziland"
    },
    {
        "value": "54240",
        "text": "Sweden"
    },
    {
        "value": "54242",
        "text": "Switzerland"
    },
    {
        "value": "54244",
        "text": "Syria"
    },
    {
        "value": "54246",
        "text": "Taiwan"
    },
    {
        "value": "54248",
        "text": "Tajikistan"
    },
    {
        "value": "54250",
        "text": "Tanzania"
    },
    {
        "value": "54252",
        "text": "Thailand"
    },
    {
        "value": "54254",
        "text": "Timor-Leste"
    },
    {
        "value": "54256",
        "text": "Togo"
    },
    {
        "value": "54258",
        "text": "Tokelau"
    },
    {
        "value": "54260",
        "text": "Tonga"
    },
    {
        "value": "54262",
        "text": "Trinidad and Tobago"
    },
    {
        "value": "54264",
        "text": "Tunisia"
    },
    {
        "value": "54266",
        "text": "Turkey"
    },
    {
        "value": "54268",
        "text": "Turkmenistan"
    },
    {
        "value": "54270",
        "text": "Turks and Caicos Islands"
    },
    {
        "value": "54272",
        "text": "Tuvalu"
    },
    {
        "value": "54274",
        "text": "U.S. Virgin Islands"
    },
    {
        "value": "54276",
        "text": "Uganda"
    },
    {
        "value": "54278",
        "text": "Ukraine"
    },
    {
        "value": "54280",
        "text": "United Arab Emirates"
    },
    {
        "value": "54282",
        "text": "United Kingdom"
    },
    {
        "value": "54284",
        "text": "United States Minor Outlying Islands"
    },
    {
        "value": "54286",
        "text": "Uruguay"
    },
    {
        "value": "54288",
        "text": "Uzbekistan"
    },
    {
        "value": "54290",
        "text": "Vanuatu"
    },
    {
        "value": "54292",
        "text": "Vatican"
    },
    {
        "value": "54294",
        "text": "Venezuela"
    },
    {
        "value": "54296",
        "text": "Viet Nam"
    },
    {
        "value": "54298",
        "text": "Wallis and Futuna"
    },
    {
        "value": "54300",
        "text": "Western Sahara"
    },
    {
        "value": "54302",
        "text": "Yemen"
    },
    {
        "value": "54304",
        "text": "Zambia"
    },
    {
        "value": "54306",
        "text": "Zimbabwe"
    }
]