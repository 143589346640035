<template>
  <div
    v-show="modalStore.openAsModal"
    :class="{
      'pardot-modal__mask': modalStore.openAsModal
    }"
  >
    <div ref="modalContainer" v-on-click-outside="modalStore.setCloseViewAll">
      <div class="d-flex flex-column h-100">
        <section class="d-flex justify-content-between pardot-modal__header">
          <h2 class="pardot-modal__header__title">Newsletter Subscription</h2>
          <span v-if="modalStore.openAsModal" class="d-flex justify-content-end">
            <button class="btn pt-0 pb-0 pe-0" @click="modalStore.setCloseViewAll()">
              <IconX />
            </button>
          </span>
        </section>

        <div class="col-12 d-flex flex-column flex-md-row pardot-modal__content flex-grow">
          <div class="col-12 col-md-6 pardot-modal__content__left">
            <List
              name = "subcription"
              :datasource="datasource"
              :preselected-items="payload.newLetters"
              class="pardot-modal__content__left__list w-100"
              @on-item-selected="(e: any) => listChange(e)"
            />
          </div>
          <div class="col-12 col-md-6 mt-5 mt-md-0 mb-5 mb-md-0 pardot-modal__content__right">
            <form id="divNewsletter" class="form-horizontal pull-left w-100">
              <div class="form-group pb-3">
                <div class="col-sm-12 pardot-modal__content__right__form-control">
                  <input
                    id="firstname"
                    v-model="payload.firstName"
                    class="form-control pardot-modal__content__right__form-control__input"
                    placeholder="First Name *"
                    type="text"
                  />
                  <label v-if="validation.firstName" class="error">{{
                    validation.firstName
                  }}</label>
                </div>
              </div>
              <div class="form-group pb-3">
                <div class="col-sm-12 pardot-modal__content__right__form-control">
                  <input
                    id="lastname"
                    v-model="payload.lastName"
                    class="form-control pardot-modal__content__right__form-control__input"
                    placeholder="Last Name *"
                    type="text"
                  />
                  <label v-if="validation.lastName" class="error">{{ validation.lastName }}</label>
                </div>
              </div>
              <div class="form-group pb-3">
                <div class="col-sm-12 pardot-modal__content__right__form-control">
                  <input
                    id="email"
                    v-model="payload.email"
                    class="form-control pardot-modal__content__right__form-control__input"
                    placeholder="Email Address *"
                    type="email"
                  />
                  <label v-if="validation.email" class="error">{{ validation.email }}</label>
                </div>
              </div>
              <div class="form-group pb-3">
                <div class="col-sm-12 pardot-modal__content__right__form-control">
                  <input
                    id="company"
                    v-model="payload.company"
                    class="form-control pardot-modal__content__right__form-control__input"
                    placeholder="Company *"
                    type="text"
                  />
                  <label v-if="validation.company" class="error">{{ validation.company }}</label>
                </div>
              </div>
              <div class="form-group pb-3">
                <div class="col-sm-12 pardot-modal__content__right__form-control">
                  <input
                    id="title"
                    v-model="payload.title"
                    class="form-control pardot-modal__content__right__form-control__input"
                    placeholder="Title *"
                    type="text"
                  />
                  <label v-if="validation.title" class="error">{{ validation.title }}</label>
                </div>
              </div>
              <div class="form-group pb-3">
                <div class="col-sm-12 pardot-modal__content__right__form-control">
                  <select
                    v-model="payload.country"
                    class="form-select pardot-modal__content__right__form-control__input"
                    placeholder="Country"
                  >
                    <option disabled selected value="">Country</option>
                    <option
                      v-for="(country, index) in countryList"
                      :key="`country-${index}`"
                      :value="country.text"
                      >{{ country.text }}
                    </option>
                  </select>
                  <label v-if="validation.country" class="error">{{ validation.country }}</label>
                </div>
              </div>
              <div class="form-group pb-3">
                <div class="col-sm-12">
                  <ClientOnly>
                    <RecaptchaV2 @widget-id="handleWidgetId" @load-callback="handleLoadCallback" />
                  </ClientOnly>
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-start">
                  <button id="subscribe" class="btn btn-primary" type="button" @click="subscribe()">
                    Subscribe
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { vOnClickOutside } from '@vueuse/components'
import { useWindowScroll } from '@vueuse/core'
import delay from 'lodash/delay'
import { taxonomies } from '~/models'
import { usePardotModalStore } from '~/stores/modals/pardot-modal'
import { countryList } from '~/types/modal/data-set/country-list'
import { RecaptchaV2 } from 'vue3-recaptcha-v2'

const props = defineProps<{ preselectedItems?: string[] }>()
const modalStore = usePardotModalStore()
const { email } = storeToRefs(modalStore)

const modalContainer = ref<HTMLElement | null>()
const { y: scrollY } = useWindowScroll()
const lastWindowScroll = ref(0)
const taxonomy = await fetchTaxonomy()
const datasource =
  taxonomy?.data
    ?.find((x) => x.system.codename == taxonomies.subscription_categories.codename)
    ?.terms.map((x) => x.name) ?? []
const payload = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  title: '',
  country: '',
  recaptcha: '',
  newLetters: Array<string>(0)
}

const validation = {
  firstName: ref(''),
  lastName: ref(''),
  email: ref(''),
  company: ref(''),
  title: ref(''),
  country: ref('')
}

function validate() {
  const template = 'Please enter your {0}.'

  let result = true

  if (!payload.firstName) {
    validation.firstName.value = template.replace('{0}', 'first name')
    result = false
  }

  if (!payload.lastName) {
    validation.lastName.value = template.replace('{0}', 'last name')
    result = false
  }

  if (!payload.email) {
    validation.email.value = template.replace('{0}', 'email')
    result = false
  }

  if (!payload.title) {
    validation.title.value = template.replace('{0}', 'title')
    result = false
  }

  if (!payload.company) {
    validation.company.value = template.replace('{0}', 'company name')
    result = false
  }

  if (!payload.country) {
    validation.country.value = template.replace('{0}', 'country')
    result = false
  }

  if (!payload.recaptcha) {
    result = false
  }

  return result
}

if (props.preselectedItems) {
  payload.newLetters.push(...props.preselectedItems)
}
const showModal = () => {
  document.body.classList.add('modal-open')
  modalContainer.value?.classList.add('pardot-modal__container')
  delay(() => modalContainer.value?.classList.add('show'), 100)
  lastWindowScroll.value = scrollY.value
  payload.email = email.value
}

const hideModal = () => {
  document.body.classList.remove('modal-open')
  modalContainer.value?.classList.remove('pardot-modal__container')
  modalContainer.value?.classList.remove('show')
  window.scrollTo({
    top: lastWindowScroll.value,
    behavior: 'instant'
  })
}

watch(
  () => modalStore.openAsModal,
  (isOpenAsModal) => {
    if (isOpenAsModal) {
      showModal()
    } else {
      hideModal()
    }
  }
)
onUnmounted(() => hideModal())

async function subscribe() {
  if (!validate()) return

  const response = await useFetch('/api/subscribe-to-newsletter', {
    body: payload,
    method: 'post'
  })

  if (response.data.value?.success) {
    modalStore.setCloseViewAll()
  }
}

function listChange(values: string[]) {
  payload.newLetters = values
}

const handleWidgetId = (widgetId: number) => {
  //console.log('Widget ID: ', widgetId)
}

const handleLoadCallback = (response: unknown) => {
  console.log('Load callback', response)
  payload.recaptcha = <string>response
}
</script>
<style lang="scss" scoped>
.error {
  color: #a94442;
}
</style>
